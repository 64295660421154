import "./Team.css";
import React, { useRef, useState, useEffect } from "react";
import { persons as allPersons, personsMobile } from "../../utils/constants";
import arrow from "../../images/Main/arrow.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowSize from "../../hooks/useWindowSize";
import LoadableImage from "../LoadableImage/LoadableImage";
import DrumSlider from "../DrumSlider/DrumSlider";

const Team = () => {
  const slider3Ref = useRef(null);
  const [persons, setPersons] = useState(allPersons);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width < 576 && size.width !== 0) {
      setPersons(personsMobile.slice(0, 7));
    } else {
      setPersons(allPersons);
    }
  }, [size.width]);

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button
        className={`team__button team__button_type_left ${className}`}
        onClick={onClick}
      >
        <img src={arrow} className="team__image" alt="Стрелка влево" />
      </button>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button
        className={`team__button team__button_type_right ${className}`}
        onClick={onClick}
      >
        <img src={arrow} className="team__image" alt="Стрелка вправо" />
      </button>
    );
  };

  let settings3 = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    arrows: true,
    focusOnSelect: true,
    centerPadding: "0",
    swipeToSlide: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 4,
          arrows: true,
          focusOnSelect: true,
          centerMode: false,
          centerPadding: "0",
          swipeToSlide: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 576,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: 2,
          rows: 2,
          arrows: true,
          focusOnSelect: true,
          centerMode: false,
          centerPadding: "0",
          swipeToSlide: true,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <section className="team">
      <h2 className="section-title section-title_type_team">
        Команда СОДА, которая реализует интерьер вашей мечты
      </h2>
      <div className="team__container">
        <Slider className="persons" ref={slider3Ref} {...settings3}>
          {persons.map((person) => (
            <article className="person" key={person.id}>
              <LoadableImage
                src={person.image}
                className="person__image"
                alt={person.name}
              />
              <h4 className="person__title">{person.name}</h4>
              <p className="person__subtitle">{person.position}</p>
            </article>
          ))}
          {size.width < 576 && size.width !== 0 && (
            <article className="persons__button">
              <a className="persons__link" href="/about-us">
                <div className="persons__container">
                  {personsMobile.slice(7, 11).map((person) => (
                    <LoadableImage
                      src={person.image}
                      className="persons__image"
                      alt={person.name}
                      key={person.id}
                    />
                  ))}
                </div>
                ПОСМОТРЕТЬ ВСЮ КОМАНДУ
              </a>
            </article>
          )}
        </Slider>
      </div>
      <div className="team__info">
        <p className="team__text">
          Все составляющие вашего проекта будут выполнены идеально нашей
          командой профессионалов
        </p>
        <p className="team__text">
          За каждый этап будет отвечать узкопрофильный специалист в своей сфере
        </p>
        <p className="team__text">
          СОДА сможет воплотить вашу мечту об идеальном доме от идеи до
          реализации
        </p>
      </div>
      <div className="team__drum">
        <DrumSlider />
      </div>
    </section>
  );
};

export default Team;
