import "../Aparts/Aparts.css";
import React, { useState } from "react";
import Price from "../Price/Price";
import contractingImage from "../../images/contracting/promo.jpg";

import Questions from "../Questions/Questions";
import Quiz from "../Quiz/Quiz";
import Feedback from "../Feedback/Feedback";
import manuscriptImage from "../../images/Object/manuscript.svg";
import LoadableImage from "../LoadableImage/LoadableImage";

import image from "../../images/gallery/townhouse/15.jpg";

import openIcon from "../../images/Main/union.svg";
import { advantagesServices, patterns, questions } from "../../utils/constants";

import { Accordion, Panel } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import Patterns from "../Patterns/Patterns";
import Builders from "../Builders/Builders";

import promoImage from "../../images/pages/renovation.jpg";

const Renovation = ({ handleOpenPopup, handleOpenPopupRequest }) => {
  const [activeKey, setActiveKey] = useState(null);

  const handleSelect = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <section className="aparts">
      <section
        className="promo promo_type_cont promo_type_ren"
        style={{ justifyContent: "center" }}
      >
        <LoadableImage
          src={promoImage}
          className="promo__image promo__image_type_ren"
          alt="Визуализация интерьера"
        />
        <div className="promo__container">
          <div className="promo__info">
            <h1 className="promo__title" style={{ width: "100%" }}>
              Ремонт квартир и загородных домов "под ключ"
            </h1>
            <div className="promo__buttons">
              <button
                className="button button_type_glass"
                onClick={handleOpenPopup}
              >
                Рассчитать стоимость
              </button>
            </div>
          </div>
        </div>
        <div className="promo__gradient"></div>
      </section>
      <LoadableImage
        src={manuscriptImage}
        className="main__manuscript"
        alt="Рукопись «Ваше новое качество жизни»"
      />
      <section className="advantages advantages_type_cont">
        <h2
          className="section-title section-title_type_team"
          style={{ marginBottom: 0 }}
        >
          Преимущества для заказчика
        </h2>
        <p className="advatages__text">при заказе услуги</p>
        <LoadableImage
          src={image}
          className="advantages__image advantages__image_type_cont"
          alt="Визуализация интерьера"
        />
        <ul className="advantages__list advatages__list_type_cont">
          {advantagesServices.map((advantage) => (
            <li className="advantage advantage_type_cont" key={advantage.id}>
              <p className="advantage__number">{advantage.id}</p>
              <div className="advantage__container">
                <h4 className="advantage__title advantage__title_type_cont">
                  {advantage.title}
                </h4>
                <p className="advantage__text advantage__text_type_cont">
                  {advantage.text}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </section>
      <div className="service__question">
        <h2 className="service__question-title">Стоимость</h2>
        <Accordion activeKey={activeKey} onSelect={handleSelect}>
          <Panel
            eventKey="0"
            className="questions__container"
            header={
              <div className="questions__title-container">
                <h3 className="question__question">
                  Ремонтно-отделочные работы от 40 000 руб. за м2
                </h3>
                <button
                  type="button"
                  className={`button questions__button ${
                    activeKey === "0" ? "questions__button_active" : ""
                  }`}
                >
                  <img
                    src={openIcon}
                    className={`question__open-image ${
                      activeKey === "0" ? "questions__open-image_active" : ""
                    }`}
                    alt="Значок вверх"
                  />
                </button>
              </div>
            }
          >
            <div className="questions__answer questions__answer_active">
              <div className="service__answer">
                <ul className="policy__list service__answer-container ">
                  <li className="policy__element servise__answer-text">
                    Приемка квартиры
                  </li>
                  <li className="policy__element servise__answer-text">
                    Расчет сметы
                  </li>
                  <li className="policy__element servise__answer-text">
                    Перепланировка
                  </li>
                  <li className="policy__element servise__answer-text">
                    Подготовка к ремонту
                  </li>
                  <li className="policy__element servise__answer-text">
                    Инженерный проект
                  </li>
                  <li className="policy__element servise__answer-text">
                    Составление графика производственных работ
                  </li>
                  <li className="policy__element servise__answer-text">
                    Составление общего бюджета
                  </li>
                  <li className="policy__element servise__answer-text">
                    Еженедельные планерки
                  </li>
                  <li className="policy__element servise__answer-text">
                    Замена инженерных систем
                  </li>
                  <li className="policy__element servise__answer-text">
                    Выравнивание пола
                  </li>
                  <li className="policy__element servise__answer-text">
                    Отделка стен (обои, покраска, декоративная штукатурка)
                  </li>
                  <li className="policy__element servise__answer-text">
                    Отделка потолков (натяжной, гкл)
                  </li>
                  <li className="policy__element servise__answer-text">
                    Укладка пола (ламинат, паркет)
                  </li>
                  <li className="policy__element servise__answer-text">
                    Укладка плитки, крупноформатный керамогранит
                  </li>
                  <li className="policy__element servise__answer-text">
                    Монтаж декора (молдинги, родекор, стеновые панели)
                  </li>
                  <li className="policy__element servise__answer-text">
                    Контроль и координация работ поставщиков
                  </li>
                  <li className="policy__element servise__answer-text">
                    Контроль и координация работ субподрядчиков
                  </li>
                  <li className="policy__element servise__answer-text">
                    Приемка материалов на объекте
                  </li>
                  <li className="policy__element servise__answer-text">
                    Приемка мебели на объекте
                  </li>
                  <li className="policy__element servise__answer-text">
                    Технический надзор
                  </li>
                  <li className="policy__element servise__answer-text">
                    Услуги склада
                  </li>
                  <li className="policy__element servise__answer-text">
                    Исполнительная документация
                  </li>
                </ul>
              </div>
            </div>
          </Panel>
        </Accordion>
      </div>
      <Patterns patterns={patterns} />
      <Builders />
      <Price />
      <Feedback />
      <Questions questions={questions} />
      <Quiz handleOpenPopupRequest={handleOpenPopupRequest} />
    </section>
  );
};

export default Renovation;
