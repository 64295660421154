import "./About.css";
import React, { useEffect } from "react";
import image from "../../images/about/about.png";
import { persons } from "../../utils/constants";

import LoadableImage from "../LoadableImage/LoadableImage";
import DrumSlider from "../DrumSlider/DrumSlider";

import useWindowSize from "../../hooks/useWindowSize";

const About = () => {
  const size = useWindowSize();

  const columns = [[], [], []];
  persons.forEach((person, index) => {
    columns[index % 3].push(person);
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="about">
      <div className="about__promo">
        <h2 className="about__title">НАША КОМАНДА</h2>
        <img
          className="about__image"
          src={image}
          alt="Общая фотография команды"
        />
        <div className="promo__gradient"></div>
      </div>
      <div className="about__columns">
        {size.width >= 576
          ? columns.map((column, columnIndex) => (
              <div className="about__column" key={columnIndex}>
                {column.map(
                  (person, personIndex) =>
                    person.imageGood && (
                      <div className="about__person" key={personIndex}>
                        <LoadableImage
                          className="about__person-image"
                          src={person.imageGood}
                          alt={person.name}
                        />
                        <p className="about__person-title">
                          {person.altName ? person.altName : person.name}
                        </p>
                        <p className="about__person-subtitle">
                          {person.altPosition
                            ? person.altPosition
                            : person.position}
                        </p>
                      </div>
                    )
                )}
              </div>
            ))
          : persons.map((person, personIndex) => (
              <div className="about__person" key={personIndex}>
                <LoadableImage
                  className="about__person-image"
                  src={person.altImage ? person.altImage : person.imageGood}
                  alt={person.name}
                />
                <p className="about__person-title">{person.name}</p>
                <p className="about__person-subtitle">{person.position}</p>
              </div>
            ))}
      </div>
      <div className="team__info">
        <p className="team__text">
          Все составляющие вашего проекта будут выполнены идеально нашей
          командой профессионалов
        </p>
        <p className="team__text">
          За каждый этап будет отвечать узкопрофильный специалист в своей сфере
        </p>
      </div>
      <div className="about__slider">
        <DrumSlider />
      </div>
    </section>
  );
};

export default About;
