import "./Header.css";
import React from "react";

import Navigation from "../Navigation/Navigation";
import Burger from "../Burger/Burger";
import Logo from "../Logo/Logo";

const Header = ({ isBurger, handleSwitchBurger, handleOpenPopup }) => {
  return (
    <header className="header">
      <div className="header__container">
        <Logo />
        <div className="header__navigation">
          <Navigation />
          <div className="header__bin">
            <div className="header__contacts">
              <a
                className="header__link"
                href="tel:89335672715"
                target="_blank"
                rel="noreferrer"
              >
                +7 (933) 567-27-15
              </a>
              <a
                className="header__link header__link_type_mail"
                href="mailto:soda@penta.pro"
                target="_blank"
                rel="noreferrer"
              >
                soda@penta.pro
              </a>
            </div>
            <button className="button" onClick={handleOpenPopup}>
              Заказать проект
            </button>
          </div>
        </div>
        <button
          className="header__burger-button"
          onClick={handleSwitchBurger}
        ></button>
      </div>
      {isBurger && (
        <Burger
          handleOpenPopup={handleOpenPopup}
          setBurger={handleSwitchBurger}
        />
      )}
    </header>
  );
};

export default Header;
