import "./Prices.css";
import React, { useState, useEffect } from "react";
import { Accordion, Panel } from "rsuite";

import Gallery from "../Gallery/Gallery";
import { galleryHouses } from "../../utils/gallery";
import Advantages from "../Advantages/Advantages";
import Price from "../Price/Price";

import { advantagesPrice } from "../../utils/constants";

import openIcon from "../../images/Main/union.svg";
import "rsuite/dist/rsuite-no-reset.min.css";

import LoadableImage from "../LoadableImage/LoadableImage";

import image from "../../images/contracting/image.png";

import { Helmet } from "react-helmet";

const Prices = ({ handleOpenPopup }) => {
  const questions = [
    {
      title: "Дизайн-проект интерьера",
      price: "от 5 000 руб. за м2",
      text: (
        <div className="service__answer">
          <p className="questions__answer">
            Студия интерьерного дизайна СОДА предлагает услуги по разработке
            дизайн проекта жилых и коммерческих помещений. Мы создаем стильные,
            эргономичные пространства, которые соответствуют вашим пожеланиям,
            учитывая ваш бюджет и особенности объекта.
          </p>
          <p className="questions__answer">Что входит в дизайн проект:</p>
          <ul className="question__list">
            <li className="question__point">
              На первом этапе дизайнер выезжает на объект или изучает чертежи.  
            </li>
            <li className="question__point">
              Составляется обмерный план, который служит основой для
              проектирования. 
            </li>
            <li className="question__point">
              На встрече определяются пожелания клиента.  
            </li>
            <li className="question__point">
              Разрабатываются до трех вариантов планировочного решения с
              расстановкой мебели, зонированием и эргономикой. 
            </li>
            <li className="question__point">
              Определяется точная стоимость дизайн проекта.
            </li>
            <li className="question__point">3D-визуализация.  </li>
            <li className="question__point">
              Подготавливается спецификация материалов, мебели и декора с
              артикулами.  
            </li>
            <li className="question__point">
              Создаются чертежи для строителей: развертки стен, планы
              коммуникаций, полов, потолков и нестандартных элементов.   
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Авторский надзор",
      price: "от 50 000 руб. в месяц",
      text: (
        <div className="service__answer">
          <p className="questions__answer">
            На этапе реализации важно контролировать процесс работ, чтобы
            сохранить соответствие проекту. В рамках авторского сопровождения:  
          </p>
          <ul className="question__list">
            <li className="question__point">
              Мы посещаем объект раз в неделю (до 3 часов);  
            </li>
            <li className="question__point">
              Предоставляем фотоотчет (WhatsApp или Telegram);  
            </li>
            <li className="question__point">
              Консультируем подрядчиков и решаем нештатные вопросы.
            </li>
          </ul>
          <p className="questions__answer">
            Если площадь объекта более 300 м², возможны дополнительные визиты.
          </p>
        </div>
      ),
    },
    {
      title: "Генеральный подряд",
      price: "от 100 000 руб. в месяц",
      text: (
        <div className="service__answer">
          <p className="questions__answer">
            Этот формат подходит тем, кто хочет полностью доверить контроль над
            реализацией нашего проекта. Мы организуем: 
          </p>
          <ul className="question__list">
            <li className="question__point">Точное оставление плана работ;</li>
            <li className="question__point">Закупку материалов и мебели;  </li>
            <li className="question__point">
              Координацию поставщиков и подрядчиков;  
            </li>
          </ul>
          <p className="questions__answer">
            Главное преимущество — мы несем ответственность за сроки, качество и
            соблюдение указанной в договоре стоимости дизайн проекта до
            завершения работ.  
          </p>
        </div>
      ),
    },
    {
      title: "Ремонтно-отделочные работы",
      price: "от 40 000 руб. за м2",
      text: (
        <div className="service__answer">
          <p className="questions__answer">
            Для тех, кто заинтересован в реализации дизайн проекта "под ключ",
            мы предлагаем услуги полного цикла ремонта. Что мы делаем:  
          </p>
          <ul className="question__list">
            <li className="question__point">Осмотр и приемка квартир;</li>
            <li className="question__point">
              Составление сметы, плана работ и общей цены проекта;  
            </li>
            <li className="question__point">
              Замена, установка инженерных систем и отопления;  
            </li>
            <li className="question__point">
              Штукатурка, шпаклевка стен, укладка плитки, окраска, а также
              создание декоративных решений;  
            </li>
            <li className="question__point">
              Монтаж потолков, полов, стеновых панелей и другого декора; 
            </li>
            <li className="question__point">
              Контроль и приемка материалов, мебели, оборудования.  
            </li>
          </ul>
          <p className="questions__answer">
            В стоимость ремонта входит также составление детальной
            исполнительной документации.  
          </p>
        </div>
      ),
    },
  ];
  const [activeKey, setActiveKey] = useState(null);

  const handleSelect = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="prices">
      <Helmet>
        <title>
          Стоимость дизайн-проекта под ключ в Санкт-Петербурге – СОДА
        </title>
        <meta
          name="description"
          content="Стоимость дизайн проекта в Санкт-Петербурге от студии СОДА. Заказывая дизайн-проект у нас, вы получите полную рабочую документацию по проекту, а чтобы результат не отличался от запланированного мы предоставляем услугу авторского надзора."
        />
      </Helmet>
      <h2 className="contacts__title">
        Стоимость дизайн-проекта под ключ в Санкт-Петербурге – СОДА
      </h2>
      <div className="prices__container">
        <p className="mon__text">
          Стоимость дизайн проекта в Санкт-Петербурге от студии СОДА. Заказывая
          дизайн-проект у нас, вы получите полную рабочую документацию по
          проекту, а чтобы результат не отличался от запланированного мы
          предоставляем услугу авторского надзора.
        </p>
        <Accordion activeKey={activeKey} onSelect={handleSelect}>
          {questions.map((data, index) => (
            <Panel
              key={index}
              eventKey={index}
              header={
                <div
                  className={`design__element prices__element ${
                    activeKey === index ? "design__element_active" : ""
                  }`}
                >
                  <h3 className="question__question prices__question">
                    {data.title} <br className="prices__wrap" /> {data.price}
                  </h3>
                  <button
                    type="button"
                    className={`button questions__button ${
                      activeKey === index ? "questions__button_active" : ""
                    }`}
                  >
                    <img
                      src={openIcon}
                      className={`question__open-image ${
                        activeKey === index
                          ? "questions__open-image_active"
                          : ""
                      }`}
                      alt="Значок вверх"
                    />
                  </button>
                </div>
              }
            >
              <div className="questions__answer questions__answer_active">
                {data.text}
              </div>
            </Panel>
          ))}
        </Accordion>
      </div>

      <button
        className="button"
        style={{ alignSelf: "center" }}
        onClick={handleOpenPopup}
      >
        Заказать проект
      </button>
      {/* <section className="advantages advantages_type_cont supervision">
        <h2
          className="section-title section-title_type_team"
          style={{ marginBottom: 0 }}
        >
          Преимущества для заказчика
        </h2>
        <p className="advatages__text">при заказе услуги</p>
        <LoadableImage
          src={image}
          className="advantages__image advantages__image_type_cont"
          alt="Визуализация интерьера"
        />
        <ul className="advantages__list advatages__list_type_cont">
          {advantagesSupervision.map((advantage) => (
            <li className="advantage advantage_type_cont" key={advantage.id}>
              <p className="advantage__number">{advantage.id}</p>
              <div className="advantage__container">
                <h4 className="advantage__title advantage__title_type_cont">
                  {advantage.title}
                </h4>
                <p className="advantage__text advantage__text_type_cont">
                  {advantage.text}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </section> */}
      <div className="mon">
        <h2 className="mon__title">Как мы работаем</h2>
        <div className="mon__container">
          <h4 className="mon__subtitle">Анализ объекта</h4>
          <p className="mon__text">На начальном этапе наш дизайнер:  </p>
          <ul className="mon__list">
            <li className="mon__point">
              Выезжает на объект для осмотра и анализа;  
            </li>
            <li className="mon__point">
              Изучает ваши чертежи, если объект находится на стадии
              планирования; 
            </li>
            <li className="mon__point">
              Оценивает текущую планировку и расположение коммуникаций. 
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Обмерный план</h4>
          <p className="mon__text">
            Обмерный план — это обязательная составляющая дизайн проекта,
            которая обеспечивает точность при разработке чертежей. В него
            входят:  
          </p>
          <ul className="mon__list">
            <li className="mon__point">
              Точные размеры стен, выступающих конструкций, колонн и других
              элементов;  
            </li>
            <li className="mon__point">
              Указание высот, дверных и оконных проемов;    
            </li>
            <li className="mon__point">
              Места расположения всех проходящих инженерных коммуникаций.  
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Пожелания и техническое задание</h4>
          <p className="mon__text">
            На встрече с клиентом дизайнер детально обсуждает: 
          </p>
          <ul className="mon__list">
            <li className="mon__point">
              Состав семьи, их образ жизни и увлечения;   
            </li>
            <li className="mon__point">
              Необходимое оборудование, зоны и функционал помещений;    
            </li>
            <li className="mon__point">
              Ваши стилистические пожелания и индивидуальные предпочтения.   
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Планировочное решение</h4>
          <p className="mon__text">
            После сбора данных разрабатываются до трех вариантов решения
            пространства. Вы увидите: 
          </p>
          <ul className="mon__list">
            <li className="mon__point">
              Как квартира будет выглядеть сверху;  
            </li>
            <li className="mon__point">
              Расположение мебели, зонирование и функциональное наполнение;    
            </li>
            <li className="mon__point">
              Проходные зоны и принципы эргономики.  
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Расчет бюджета и составление сметы</h4>
          <p className="mon__text">
            Стоимость дизайн проекта и дальнейшей реализации важно определить на
            ранних этапах разработки. Мы составляем расчет, который поможет
            понять:  
          </p>
          <ul className="mon__list">
            <li className="mon__point">
              Какие материалы используются (отделочные покрытия, сантехника,
              светильники);    
            </li>
            <li className="mon__point">
              Какие производители мебели и оборудования лучше соответствуют
              воспроизведению вашего стиля, предпочтений и бюджета.
            </li>
          </ul>
        </div>
        <div className="mon__container">
          <h4 className="mon__subtitle">Фотореалистичная 3D-визуализация</h4>
          <p className="mon__text">
            Для лучшего понимания итогового результата мы создаем детальную
            модель будущего дизайна интерьера, используя технологии
            3D-визуализации. Это позволяет вам «заглянуть» в готовое
            пространство и оценить:  
          </p>
          <ul className="mon__list">
            <li className="mon__point">Планировку и дизайн помещений;    </li>
            <li className="mon__point">
              Сочетание текстур, цветов и декоративных элементов;   
            </li>
            <li className="mon__point">
              Все детали меблировки и декора, включая освещение.   
            </li>
          </ul>
        </div>
      </div>
      <Gallery gallery={galleryHouses} small={true} />
      <Advantages
        title="6 причин заказать у нас дизайн-проект квартиры под ключ в Санкт-Петербурге"
        advantages={advantagesPrice}
      />
      <Price />
    </section>
  );
};

export default Prices;
